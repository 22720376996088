import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from './store'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2'

//  vue loading
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'

import '../src/assets/css/fontawesome-all.min.css'
import '../src/assets/scss/style.scss'

import 'animate.css/animate.min.css'
import 'swiper/css';
import 'swiper/css/effect-fade';

import 'sweetalert2/dist/sweetalert2.min.css';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// add token to localstorage
// if (localStorage.getItem('token')) {
//     axios.defaults.headers.common['Authorization'] = 'bearer ' + localStorage.getItem('token');
// }

// check error handling
// axios.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     if (error.response && error.response.data) {
      
//       // error token expired
//       if (error.response.data.status == 401) {
//         console.log(error.response.data.message);
//         Swal.fire({icon: 'error', text: error.response.data.message});
//         store.dispatch('auth/logout');
//         router.replace('/login');
//       }

//       // error email not verified
//       if (error.response.status == 403) {
//         router.replace('/email-verification');
//       }

//       // error validasi form
//       if (error.response.status == 422) {
//         let message = '';
//         Object.keys(error.response.data).forEach(element => {
//             message += error.response.data[element][0] + '<br />'
//         })
//         Swal.fire({icon: 'error', html: message});
//       }

//       // error message
//       if (error.response.status == 400) {
//         Swal.fire({icon: 'error', html: error.response.data.message});
//       }

//       return Promise.reject(error);
//     }

//     // error network
//     if (!error.status) {
//       Swal.fire({icon: 'error', html: error.message});
//     }
//     return Promise.reject(error);
// });

// guard for auth
// router.beforeEach((to, from, next) => {
//     if (to.path == '/' || 
//         to.path == '/register' || 
//         to.path == '/login' || 
//         to.path == '/forget-password' || 
//         to.path == '/email-verify' || 
//         to.path == '/reset-password' || 
//         to.path == '/certificate-validation' || 
//         to.path == '/privacy-policy' || 
//         to.path == '/privacy-refund' ||
//         to.path == '/term-and-condition'
//         ) {
//         next();
//     } else {
//         var hasToken = localStorage.getItem('token');
//         if (hasToken) {
//             next();
//         } else {
//             next('/login');
//         }
//     }
// })


// Firebase
import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import "firebase/compat/analytics";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAsUmHxqP_vQKDTXlzrJdAYu2QFgjMXgrM",
  authDomain: "gps-tracker-3c2a1.firebaseapp.com",
  databaseURL: "https://gps-tracker-3c2a1.firebaseio.com",
  projectId: "gps-tracker-3c2a1",
  storageBucket: "gps-tracker-3c2a1.appspot.com",
  messagingSenderId: "893074004983",
  appId: "1:893074004983:web:f27abdb4faaa62d395acf2"
};

firebase.initializeApp(firebaseConfig);

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(LoadingPlugin, {
    color: 'blue',
    loader: 'dots',
    height: 64,
    width: 64,
  })
  .mount("#app");
