import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/Dashboard.vue'),
    },
    {
        path: '/category',
        name: 'Category',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/Category.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/Register.vue'),
    },
    {
        path: '/register-confirmation',
        name: 'RegisterConfirm',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/RegisterConfirm.vue'),
    },
    // {
    //     path: '/',
    //     name: 'Home',
    //     component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/Home.vue'),
    // },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
