import axios from 'axios'; 


export default {
    namespaced: true,
    state: {
        event: {},
        category: {},
        registran: {}
    },
    mutations: {
        setEvent (state, data) {
            state.event = data
        },
        setCategory (state, data) {
            state.category = data
        },
        setRegistran (state, data) {
            state.registran = data
        }
    },
    getters: {
        getEvent_: state => { return state.event },
        getCategory_: state => { return state.category },
        getRegistran_: state => { return state.registran }
    },
    actions: {
        getEvent(_, _params){
            return new Promise((resolve,reject) => {
                axios.get('list-event', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        getCategory(_, _params){
            return new Promise((resolve,reject) => {
                axios.get('list-category', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        register(_, _params){
            return new Promise((resolve,reject) => {
                axios.post('submit-register', _params).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },
    },
}